import React, { useEffect, useState, useRef } from 'react';
import { FaShieldAlt, FaCheck } from 'react-icons/fa';
// Import the 1Password save button package
import '@1password/save-button';
// Import the activateOPButton function directly if available
import { activateOPButton } from '@1password/save-button';

const OnePasswordSaveButton = ({ identity }) => {
  const [saveStatus, setSaveStatus] = useState('idle'); // idle, saving, success, error
  const [isClient, setIsClient] = useState(false);
  const buttonRef = useRef(null);
  const activationAttempts = useRef(0);

  // Function to get the direct download URL for the avatar
  const getAvatarDownloadUrl = () => {
    if (!identity.avatar?.url) return '';
    
    const url = identity.avatar.url;
    const type = identity.avatar.type;
    
    // For SVG URLs from DiceBear, convert to PNG
    if (url.includes('dicebear.com') && url.includes('/svg')) {
      return url.replace('/svg', '/png');
    }
    
    // For UI Avatars, ensure PNG format is used
    if (url.includes('ui-avatars.com') && !url.includes('format=png')) {
      return url + (url.includes('?') ? '&' : '?') + 'format=png';
    }
    
    // For avataaars.io, we can't easily convert to PNG, so return as is
    // For randomuser.me and xsgames.co, the URLs are already JPG
    // For faker images, they're already in the right format
    
    return url;
  };

  // Function to encode the save request
  const encodeOPSaveRequest = (saveRequest) => {
    return btoa(JSON.stringify(saveRequest));
  };

  // Build the save request with identity data
  const buildSaveRequest = () => {
    const avatarUrl = getAvatarDownloadUrl();
    
    const identityData = {
      title: `Identity: ${identity.name?.full}`,
      fields: [
        {
          autocomplete: "username",
          value: identity.username,
        },

        {
          autocomplete: "current-password", // Best match for a passphrase in 1Password fields
          value: identity.passphrase,
        },

        {
          autocomplete: "name",
          value: identity.name?.full,
        },
        {
          autocomplete: "email",
          value: identity.name?.email,
        },
        {
          autocomplete: "nickname", // Hack to find a way to get this its own field
          value: identity.disposableEmail?.email
        },
        {
          autocomplete: "url",
          value: identity.disposableEmail?.inboxUrl
        },
        {
          autocomplete: "tel",
          value: identity.phone,
        },
        {
          autocomplete: "street-address",
          value: identity.address?.street,
        },
        {
          autocomplete: "address-level2",
          value: identity.address?.city,
        },
        {
          autocomplete: "address-level1",
          value: identity.address?.state,
        },
        {
          autocomplete: "postal-code",
          value: identity.address?.zip,
        },
        {
          autocomplete: "country",
          value: "US",
        },
        {
          autocomplete: "bday",
          value: identity.birthday,
        },
        {
          autocomplete: "photo",
          value: avatarUrl,
        },
      ],
      notes: `Open disposable email inbox: ${identity.disposableEmail?.inboxUrl}


Download profile picture (avatar): ${avatarUrl}

--

*Identity generated by StrongPhrase.net Identity Generator: https://strongphrase.net/#/identity*
`
    };
    
    return identityData;
  };

  // Function to activate the 1Password button
  const activateButton = () => {
    console.log("Attempting to activate 1Password button");
    
    // Try multiple activation methods
    try {
      // Method 1: Use the imported function if available
      if (typeof activateOPButton === 'function') {
        console.log("Using imported activateOPButton function");
        activateOPButton();
      } 
      // Method 2: Use window.activateOPButton if available
      else if (typeof window !== 'undefined' && typeof window.activateOPButton === 'function') {
        console.log("Using window.activateOPButton function");
        window.activateOPButton();
      }
      // Method 3: Dispatch a custom event that might trigger activation
      else {
        console.log("Dispatching custom event for activation");
        const event = new CustomEvent('op-button-ready');
        document.dispatchEvent(event);
      }
      
      // Check if the button is actually activated
      setTimeout(() => {
        const button = buttonRef.current?.querySelector('onepassword-save-button');
        if (button && button.shadowRoot) {
          const innerButton = button.shadowRoot.querySelector('button[data-onepassword-save-button]');
          if (innerButton && innerButton.disabled) {
            console.log("Button still disabled, retrying activation");
            activationAttempts.current += 1;
            
            // Retry activation a few times with increasing delay
            if (activationAttempts.current < 5) {
              setTimeout(activateButton, 500 * activationAttempts.current);
            }
          } else {
            console.log("Button activated successfully");
          }
        }
      }, 100);
    } catch (error) {
      console.error("Error activating 1Password button:", error);
    }
  };

  // Activate the 1Password button when the component mounts or when identity changes
  useEffect(() => {
    // Set isClient to true since we're now on the client side
    setIsClient(true);
    activationAttempts.current = 0;
    
    // Reset activation attempts when identity changes
    setTimeout(activateButton, 100);

    // Listen for the 1Password save button click event
    const handleSaveButtonClick = () => {
      console.log("Save button clicked");
      setSaveStatus('saving');
      
      // Simulate a successful save after a short delay
      setTimeout(() => {
        setSaveStatus('success');
        
        // Reset status after 3 seconds
        setTimeout(() => {
          setSaveStatus('idle');
        }, 3000);
      }, 1000);
    };

    // Add event listener for the 1Password save button click
    document.addEventListener('op-save-button-clicked', handleSaveButtonClick);

    // Clean up event listener
    return () => {
      document.removeEventListener('op-save-button-clicked', handleSaveButtonClick);
    };
  }, [identity]);

  // Add an effect to handle modal visibility changes
  useEffect(() => {
    // Function to handle dialog visibility changes
    const handleDialogVisibilityChange = (mutations) => {
      for (const mutation of mutations) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'open') {
          const dialog = mutation.target;
          if (dialog.open) {
            console.log("Dialog opened, activating button");
            // Dialog was opened, activate the button with a delay
            setTimeout(activateButton, 300);
          }
        }
      }
    };

    // Set up a mutation observer to watch for dialog open/close
    const observer = new MutationObserver(handleDialogVisibilityChange);
    
    // Find all dialogs in the document
    const dialogs = document.querySelectorAll('dialog');
    dialogs.forEach(dialog => {
      observer.observe(dialog, { attributes: true });
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  // Encode the save request
  const encodedSaveRequest = encodeOPSaveRequest(buildSaveRequest());

  // If we're not on the client side yet, return a placeholder
  if (!isClient) {
    return (
      <div className="mt-4 relative">
        <button 
          className="flex items-center justify-center gap-2 py-2 px-4 rounded transition-colors bg-gray-400 text-white w-full"
          disabled
        >
          <span className="animate-pulse">Loading...</span>
        </button>
      </div>
    );
  }

  return (
    <div className="relative">
      {/* Status indicator */}
      {saveStatus !== 'idle' && (
        <div className={`absolute inset-0 flex items-center justify-center z-10 rounded bg-opacity-70 ${
          saveStatus === 'success' ? 'bg-green-500' : 'bg-blue-500'
        }`}>
          {saveStatus === 'success' ? (
            <div className="flex items-center gap-2 text-white font-medium">
              <FaCheck />
              <span>Saved to 1Password</span>
            </div>
          ) : (
            <div className="flex items-center gap-2 text-white font-medium">
              <span className="animate-spin h-4 w-4 border-2 border-white border-t-transparent rounded-full"></span>
              <span>Saving...</span>
            </div>
          )}
        </div>
      )}

      {/* The actual 1Password button that will be activated by the 1Password extension */}
      <div ref={buttonRef} className="flex justify-center items-center">
        <onepassword-save-button
          data-onepassword-type="login"
          value={encodedSaveRequest}
          lang="en"
          class="light"
          data-theme="light"
          padding="none">
        </onepassword-save-button>
      </div>
    </div>
  );
};

export default OnePasswordSaveButton; 
class ToolbarStorage {
  static KEYS = {
    AVATAR_PROVIDERS: 'toolbar_avatar_providers',
    GENDER_PREFERENCE: 'gender_preference'
  };

  static getItem(key, defaultValue = null) {
    if (typeof window === 'undefined') return defaultValue;
    
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : defaultValue;
    } catch (error) {
      console.error('Error reading from localStorage:', error);
      return defaultValue;
    }
  }

  static setItem(key, value) {
    if (typeof window === 'undefined') return;

    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error('Error writing to localStorage:', error);
    }
  }

  // Avatar providers specific methods
  static getAvatarProviders(defaultProviders) {
    return this.getItem(this.KEYS.AVATAR_PROVIDERS, defaultProviders);
  }

  static setAvatarProviders(providers) {
    this.setItem(this.KEYS.AVATAR_PROVIDERS, providers);
  }

  // Gender preference specific methods
  static getGenderPreference(defaultValue = null) {
    return this.getItem(this.KEYS.GENDER_PREFERENCE, defaultValue);
  }

  static setGenderPreference(gender) {
    this.setItem(this.KEYS.GENDER_PREFERENCE, gender);
  }
}

export default ToolbarStorage; 